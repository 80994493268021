import styled from '@emotion/styled'
import React from 'react'
import { InlineWidget } from 'react-calendly'

import { NarrowSection } from '@components/Section'

const Calendly: React.FC<{}> = () => {
  return (
    <NarrowSection marginTop={'0px'}>
      <InlineWidget
        styles={{
          height: '1000px',
        }}
        pageSettings={
          {
            // hideEventTypeDetails: true,
            // hideLandingPageDetails: true,
          }
        }
        url="https://calendly.com/_derek/discovery-call"
      />
    </NarrowSection>
  )
}

export default Calendly
