import React from 'react'
import { useColorMode } from 'theme-ui'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '@components/Layout'
import {
  Section,
  NarrowSection,
  SectionStyler,
  SectionTopHeader,
  SectionWrapper,
  SectionContainer,
} from '@components/Section'
import Seo from '@components/SEO'

import Vector from '@components/Vector'

import {
  SectionParagraph,
  SectionHeading,
  SectionHeader,
} from '@components/Section/SectionHeader'

import { CallToButton } from '@components/Button'
import Calendly from '../components/Calendly'

const SchedulePage = (data) => {
  const [colorMode] = useColorMode()
  const teamSectionBgColor = `${(p) => p.theme.colors.primaryContrast}`

  return (
    <Layout>
      <Seo
        image="/images/og/schedule.png"
        url="/schedule"
        title="Schedule"
        description="Schedule a discovery call with our team."
      />
      <SectionTopHeader>
        <SectionWrapper>
          <SectionHeader>Scheduling</SectionHeader>
          <SectionHeading.h1>Let's Talk</SectionHeading.h1>
          <SectionParagraph>
            Please fill out the form to schedule a discovery call with our team below.
          </SectionParagraph>
        </SectionWrapper>
      </SectionTopHeader>

      <Calendly/>
    </Layout>
  )
}

export default SchedulePage
